import { ref, watch, getCurrentInstance } from "vue";
import store from "@/store/";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import type { ElTree } from 'element-plus'

export default function useComponentTree() {
  
  const { emit } = getCurrentInstance() as any;

  interface Tree {
    id: number
    label: string
    children?: Tree[]
  }

  const transferData = ref({})
  const filterText = ref('')
  const treeRef = ref<InstanceType<typeof ElTree>>()

  const treeData = ref();

  watch(filterText, (val) => {
    treeRef.value!.filter(val)
  })

  const deleteNode = (id: number) => {
    store.dispatch(Actions.PROFILE_CATEGORY_DELETE, { id: id }).then(async() => {
      Swal.fire({
        allowOutsideClick: false,
        text: "Successfully removed",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Continue",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        }
      }).then(() => {
        emit('reload')
      })
    }).catch(() => {
      Swal.fire({
        text: "Sorry, looks like there are some errors detected, please try again.",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Continue",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      })
    });
  }

  const filterNode = (value: string, data: Tree) => {
    const string = data.label.toLowerCase()
    if (!value.toLowerCase()) return true
    return string.indexOf(value.toLowerCase()) !== -1
  }
    
  const createTree = async (data) => {
    const treeProperties = data.items.map(item => {
      const properties = {
        "id": item.id,
        "parentId": item.parent_id,
        "label": item.title,
        "position": item.position,
      };

      return properties;
    });
    const test = await recordsToTree(treeProperties)
    return test
  }

  const recordsToTree = async (records) => {
    const lookup = new Map(records.map(({ ...record }) => [record.id, record])) as any;
    lookup.set(null, { children: [] });

    for (const { id, parentId, position } of records) {
      const record = lookup.get(id) as any;
      const parent = lookup.get(parentId) as any;
      parent.children ||= [];
      parent.children[position] = record;
    }
    
    treeData.value = lookup.get(null).children;
    return treeData.value
  }

  const handleDropEnd = (
    draggingNode,
    dropNode,
    dropType,
    ev
  ) => {

    let parentId;
    let position;

    if (dropNode.parent.data.children) {
      for (let i = 0; i < dropNode.parent.data.children.length; i++) {
        if (dropNode.parent.data.children[i].id == draggingNode.data.id) {
          parentId = dropNode.parent.data.id;
          position = i;
          break;
        }
      }
    } else {
      for (let i = 0; i < dropNode.parent.data.length; i++) {
        if (dropNode.parent.data[i].id == draggingNode.data.id) {
          parentId = null;
          position = i;
          break;
        }
      }
    }

    if (parentId === undefined) {
      if (dropNode.data.children == undefined) {
        for (let i = 0; i < dropNode.data.length; i++) {
          if (dropNode.data[i].id == draggingNode.data.id) {
            parentId = dropNode.data.id;
            position = i;
            break;
          }
        }
      } else {
        for (let i = 0; i < dropNode.data.children.length; i++) {
          if (dropNode.data.children[i].id == draggingNode.data.id) {
            parentId = dropNode.data.id;
            position = i;
            break;
          }
        }
      }
    }

    store.dispatch(Actions.PROFILE_CATEGORY_EDIT, {routeId: draggingNode.data.id, params: {parent_id: parentId, position: position }}).catch((error) => {
      Swal.fire({
        text: "Sorry, looks like there are some errors detected, please try again.",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
        confirmButton: "btn btn-primary",
        },
      });
      return false;
    })
  }

  return {
    treeData,
    filterText,
    treeRef,
    createTree,
    handleDropEnd,
    filterNode,
    deleteNode
  }
}