import { ref, computed } from "vue";
import { useStore } from "vuex";

export default function useTooltip() {
  const store = useStore();

  const createTooltip = (fieldType, fieldValue, rowData) => {
    let appSettingAction;
    let appSettingActionName;
    let appSettingPath = "default";
    if (fieldType == "payment_provider") {
      appSettingActionName = "payment_providers"
    } else if (fieldType == "payment_method") {
      appSettingActionName = "payment_methods"
      appSettingPath = "payment_methods" 
    } else if (fieldType == "connection_type") {
      appSettingActionName = "connection_types"
    } else if (fieldType == "channel_type") {
      appSettingActionName = "channel_types"
    } else if (fieldType == "media_type") {
      appSettingActionName = "media_types"
    } else if (fieldType == "stock_type") {
      appSettingActionName = "stock_types"     
    } else if (fieldType == "country_code") {
      appSettingActionName = "countries"
      appSettingPath = "countries"     
    } else if (fieldType == "language") {
      appSettingActionName = "languages"  
      appSettingPath = "languages"   
    } else if (fieldType == "specification_type") {
      appSettingActionName = "specification_types"
    } else if (fieldType == "product_type") {
      appSettingActionName = "product_types"
    } else if (fieldType == "automation_type") {
      appSettingActionName = "automation_types"
    } else if (fieldType == "tag_category") {
      appSettingActionName = "tag_categories"
    } else if (fieldType == "connection_category") {
      appSettingActionName = "connection_categories"
    } else if (fieldType == "carrier_type") {
      appSettingActionName = "carrier_types"
    } else if (fieldType == "label_type") {
      appSettingActionName = "label_types"
    } else if (fieldType == "filter_type") {
      appSettingActionName = "filter_types"
    } else {
      appSettingActionName = ""
    }

    if (appSettingPath == "default") {

      if (fieldType === "payment_provider") {
        appSettingAction = store.getters.appSettings.payment_methods.find(method => method.id === rowData.payment_method).providers || []
      } else {
        appSettingAction = store.getters.appTypes[appSettingActionName] || []
      }
    } else {
      appSettingAction = store.getters.appSettings[appSettingPath] || []
    }

    const tooltip = appSettingAction.find(element => element.id === fieldValue) || null;
    return tooltip?.label || ''
  }

  return {
    createTooltip
  }
}