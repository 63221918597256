import { ref } from "vue";

export default function useReactive() {
  const reactiveElements = ref({}) as any

  const addReactive = (key, value) => {
    reactiveElements.value[key] = value;
  } 

  const reRendering = () => {
    console.log('reRendering')
  }

  return {
    reactiveElements,
    addReactive,
    reRendering
  }
}