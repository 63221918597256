<template>
  <div v-if="processingData.state" class="ms-4 me-2">
    <div
      class="d-flex justify-content-start align-items-center"
    >
      <div class="fw-bold me-5">
        <span class="me-2">{{ processingData.count }}</span
        >{{ $t('table.selected') }}
      </div>
      <template v-if="processingData.actions">
        <template v-for="action in processingData.actions.actions" :key="action.type">
          <el-tooltip class="item" effect="dark" :content="$t('titles.' + action.type)" placement="bottom" v-if="action.visibility.maxSelected == null ? processingData.totalCount >= processingData.count : action.visibility.maxSelected >= processingData.count">
            <button 
              :class="'btn btn-sm btn-icon btn-bg-light btn-active-light-' + action.icon.color + ' me-2'"
              @click="$emit('loadAction', { type: 'load', data: action })"
            >
              <span class="svg-icon svg-icon-2 svg-icon-gray-500">
                <Icon :name='action.icon.name'/>
              </span>
            </button>
          </el-tooltip>
        </template>
      </template>
    </div>
  </div>

  <Modal v-if="processingData.renderAmount != 0" :key="processingData.renderAmount" @reload="reloadComponent" @transferData="transferSelectedData"
    :modalSettings="currentAction"
    :modalId="processingData.modalId" 
  />

</template>

<script>
import { defineComponent, ref, onMounted, defineEmits } from 'vue';
import { useStore } from "vuex";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import Icon from '@/components/icons/Icon.vue';
import Modal from "@/components/modals/default/v2/ModalTableActions.vue";

export default defineComponent({
  name: "",
  props: {
    processingData: Object,
    currentAction: Object
  },
  components: {
    Icon,
    Modal
  },
  emits: ["loadAction"],

  setup(props, {emit}) {
    const transferSelectedData = (data) => {
      emit('loadAction', { type: 'submit', data: data })
    }

    onMounted(() => {
      MenuComponent.reinitialization();
    })

    return {
      transferSelectedData
    };
  }
});

</script>