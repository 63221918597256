
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router"
import Flag from '@/components/flags/Flag.vue';
import Icon from '@/components/icons/Icon.vue';
import Logo from '@/components/logos/Logo.vue';
import useDateTime from '@/composables/v2/useDateTime';
import useLanguage from '@/composables/v2/useLanguage';
import useHelpers from '@/composables/v2/useHelpers';
import useInit from '@/composables/v2/useInit';
import useTooltip from '@/composables/v2/useTooltip';
import ToolbarSelections from "@/components/toolbar/ToolbarSelections.vue";
import useTableToolbar from '@/composables/v2/useTableToolbar';
import useTableCompare from '@/composables/v2/useTableCompare';
import StatusBadge from '@/components/helpers/StatusBadge.vue';
import RelationBadge from '@/components/helpers/RelationBadge.vue';

export default defineComponent({
  name: "",
  props: {
    processingData: Object,
    toolbar: Object
  },
  components: {
    Flag,
    Icon,
    Logo,
    ToolbarSelections,
    StatusBadge,
    RelationBadge
  },
  emits: ["unique", "reload", "gateway"],

  setup(props, {emit}) {
    const store = useStore()
    const route = useRoute();
    const { showDateTime } = useDateTime();
    const { convertToText } = useLanguage();
    const { getShortString, tooltipStatus, tooltipContent } = useHelpers();
    const { getEmailAddress, getEmailAddressV2 } = useInit();
    const { selectionState, selectionChange, selectionSelected, selectionData, initToolbar, selectAll, deselectAll, multipleTableRef, setBackgroundTableRow, deleteSelected, update, loadAction, renderAmount, currentAction } = useTableToolbar()
    const { maximumCompare } = useTableCompare();
    const { createTooltip } = useTooltip();

    initToolbar(props.processingData?.actions, props.processingData?.data.total, props.processingData?.data.per_page);

    const checkLevel = (data, scope) => {
      const split = data.split('.')
      if (split.length == 1) {
        return scope.row[data]
      } else if (split.length == 2) {
        return scope.row[split[0]][split[1]]
      }
    }

    const invoiceEmail = (id, uid, itemId) => {
      getEmailAddressV2('', id, uid, 'invoice_email', 'INVOICE_EMAIL', itemId)
    }

    function setUniqueId(id) {
      emit('unique', id)
    }

    function navigatePagination(page) {
      emit('reload', page);
    }

    function setStatus(id, route) {
      const object = {
          id: id,
          route: route
      }
      return store.getters.getStatus(object);
    }

    function setTag(id) {
      return store.getters.AllTag(id);
    }

    const updateField = (key: string, value: string | number, id: number) => {
      emit("gateway", {method: 'update', componentType: 'table', data: {key: key, value: value, id: id}});
		}
    
    return {
      setUniqueId,
      navigatePagination,
      setStatus,
      setTag,
      showDateTime,
      convertToText,
      checkLevel,
      invoiceEmail,
      getShortString,
      tooltipStatus,
      tooltipContent,
      selectionChange,
      selectionState,
      selectionSelected,
      selectionData,
      selectAll,
      initToolbar,
      deselectAll,
      multipleTableRef,
      setBackgroundTableRow,
      deleteSelected,
      loadAction,
      renderAmount,
      currentAction,
      maximumCompare,
      updateField,
      createTooltip,
      store,
    };
  }
});
