import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import type { UploadInstance, UploadProps, UploadUserFile } from 'element-plus'

const uploadFilesRef = ref<UploadInstance>() // Refers to Types: UploadFiles

export default function useFile() {
  const { emit } = getCurrentInstance() as any;
  const store = useStore();
  const timer = ref(null) as any

  const getFile = async (fileId: number) => {
    let options;
    if (fileId) {
      await store.dispatch(Actions.FILE, fileId).then(() => {
        options = [{ description: store.getters.currentFile.description, url: store.getters.currentFile.file_full_url }]
      })
    } else {
      options = []
    }
    return options
  }

  const createList = (file, files) => {
    // Workaround Bug. https://github.com/element-plus/element-plus/issues/9260

    clearTimeout(timer.value)

    timer.value = setTimeout(() => {
      emit("gateway", {method: 'uploadFiles', data: {key: '', value: files }});
    }, 100)
  }

  const createFiles = async (fileList, componentSettings) => {
    const dispatchAction = componentSettings.dispatchActions.save
    const paramList = componentSettings.dispatchActions.params.lines
    const imageList = new FormData()

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    // Loop through fileList
    for (let i = 0; i < fileList.length; i++) {
      imageList.append('file[]', fileList[i].raw)
    }

    // Loop through paramList
    for (let i = 0; i < paramList.length; i++) {
      imageList.append(paramList[i].key, paramList[i].value)
    }

    await store.dispatch(Actions[dispatchAction], {imageList: imageList, config: config}).then(() => {
      console.log('Dispatched')
    }).catch((error) => {
      Swal.fire({
        text: "Sorry, looks like there are some errors detected, please try again.",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
        confirmButton: "btn btn-primary",
        },
      });
      return false;
    }).finally(() => {
      uploadFilesRef.value!.clearFiles() // Clear Files
    })
  }

  return {
    getFile,
    createList,
    uploadFilesRef,
    createFiles
  }
}