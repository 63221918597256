import { ref, computed } from "vue";
import { useStore } from "vuex";

export default function useApp() {
  const store = useStore();

  const getSetting = (settings, fieldName, fieldValueRerender?) => {
    const split = settings.split('.')

    let setting;
    if (fieldName == 'payment_provider') {
      setting = store.getters.appSettings.payment_methods.find(method => method.id === fieldValueRerender).providers;
    } else if (split.length == 1) {
      setting = store.getters.appSettings[split[0]]
    } else if (split.length == 2) {
      setting = store.getters.appSettings[split[0]][split[1]]
    }

    return setting
  }

  const matchSetting = (fieldName, fieldOptions) => {
    const match = fieldOptions.find(item => item.id == fieldName);
    return match
  }

  const getSettingOptions = (fieldName: string, fieldSettings: string, fieldValueRerender?) => {
    const options = getSetting(fieldSettings, fieldName, fieldValueRerender) || []
    return options
  }

  return {
    getSettingOptions,
    matchSetting,
    getSetting
  }
}