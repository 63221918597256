import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-5" }
const _hoisted_2 = { class: "highlight" }
const _hoisted_3 = { class: "highlight-code" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("pre", {
          class: _normalizeClass(`language-${_ctx.lang}`),
          style: _normalizeStyle({ height: _ctx.getHeightInPixesls })
        }, [
          _createElementVNode("code", {
            class: _normalizeClass(`language-${_ctx.lang}`)
          }, [
            _createTextVNode("\n          "),
            _renderSlot(_ctx.$slots, "default"),
            _createTextVNode("\n        ")
          ], 2)
        ], 6)
      ])
    ])
  ]))
}