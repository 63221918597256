import { ref, computed, reactive } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import useApp from "@/composables/v2/useApp";

export default function useTable() {
  const store = useStore();
  const { getSettingOptions, matchSetting } = useApp();

  interface Status {
    colorClass: string
    label: string
  }

  const statusBadge: Status = reactive({ colorClass: '', label: ''})
  const tagsTemplate = reactive([]) as any

  const createBadge = async (fieldType, fieldName, fieldValue, fieldSettings, id, tags) => {
    if (!id) {
      return
    }

    if (fieldType == 'status') {
      const options = getSettingOptions(fieldName, fieldSettings);
      const match = matchSetting(fieldValue, options);

      if (match) {
        statusBadge.colorClass = match.class
        statusBadge.label = match.label
      }

      if (tags) {
        const tagSettings = store.getters.allTags.items;
        for (const tag of JSON.parse(tags)) {
          const tagData = tagSettings.find(item => item.id == tag);
          tagsTemplate.push({ label: tagData.name, colorClass: tagData.color }) as Status
        }
      }
    }
  }

  return {
    statusBadge,
    createBadge,
    tagsTemplate
  }
}