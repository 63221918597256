import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ElTable } from 'element-plus'
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal } from "bootstrap";
import { hideModal, showModal } from "@/core/helpers/dom";
import { useI18n } from "vue-i18n";

export default function useTableToolbar() {
  const store = useStore();
  const { emit } = getCurrentInstance() as any;
  const { t } = useI18n();
  
  const multipleTableRef = ref<InstanceType<typeof ElTable>>()
  const selectionState = ref(false)
  const selectionSelected = ref(0)
  const renderAmount = ref(0)
  const selectionData = ref({ items: [], state: false, count: 0, totalCount: 0, maxItemsPage: 0, allPageSelected: false, allSelected: false, actions: [], renderAmount: renderAmount, modalId: '' }) as any

  const modalData = ref({ state: false, settings: []})
  const currentAction = ref(null) as any

  const initToolbar = (actions, totalItems, maxItemsPage) => {
    selectionData.value.actions = actions
    selectionData.value.totalCount = totalItems
    selectionData.value.maxItemsPage = maxItemsPage
  }

  const selectionChange = (items) => {
    update(items)
  }

  const update = (data) => {
    selectionData.value.items = data
    selectionData.value.count = data.length;
    // selectionData.value.maxItemsPage = pageItems;
    // selectionData.value.totalCount = totalItems;
    selectionData.value.state = data.length > 0 ? true : false
    selectionData.value.allPageSelected = data.length == selectionData.value.maxItemsPage || data.length == selectionData.value.totalCount ? true : false
    selectionData.value.allSelected = data.length == selectionData.value.totalCount ? true : false

  }

  const selectAll = () => {
    selectionData.value.allSelected = true
    selectionData.value.count = selectionData.value.totalCount;
  }

  const deselectAll = () => {
    selectionData.value.allSelected = false // Reset
    selectionData.value.allPageSelected = false // Reset
    multipleTableRef.value?.clearSelection() // Clear selection
  }

  const setBackgroundTableRow = ({row}) => {
    const selectedItems = selectionData.value.items
    let status;

    for (let i = 0; i < selectedItems.length; i++) {
      const item = selectedItems[i] as any
      if (row.id == item.id) {
        status = 'selected'
      }
    }
    return status
  }

  const deleteSelected = () => {
    let params;

    if (selectionData.value.allSelected && selectionData.value.totalCount > selectionData.value.maxItemsPage) {
      params = 'all'
    } else {
      params = [];
      for (const item of selectionData.value.items) {
        params.push(item.id)
      }
    }

    Swal.fire({
      title: t("titles.Confirm_Action"),
      text: t('table.confirm.text', selectionData.value.count ),
      buttonsStyling: false,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: t("titles.Confirm"),
      cancelButtonText: t("titles.Cancel"),
      customClass: {
        confirmButton: "btn btn-light-primary",
        cancelButton: "btn btn-light"
      },
     }).then((result) => {
      if (result.isConfirmed) {
        store.dispatch(Actions[currentAction.value.dispatch.endpoint], { id: params })
        .then(async() => {
          emit('reload')
        })
        .catch((error) => {
          Swal.fire({
            text: t('phrases.generalApiError'),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t('titles.Continue'),
            customClass: {
            confirmButton: "btn btn-primary",
            },
          });
        })
      }
    })
  }

  const loadAction = (obj) => {
    const { type, data } = obj

    if (type == 'load') {
      currentAction.value = data

      // Check if modal is enabled
      if (data.dispatch.modal.init) {

        selectionData.value.modalId = 'es_table_action_modal_' + data.type // Set ModalID
        renderAmount.value += 1 // Rerender Modal
      } else {
        if (currentAction.value.type == "delete") {
          deleteSelected()
        }
      }

    } else if (type == 'submit') {
      submitAction(data)
    }

  }

  const submitAction = async (data) => {
    const dispatchData = await createDispatchData(data)

    store.dispatch(Actions[currentAction.value.dispatch.endpoint], dispatchData).then(async() => {
      if (currentAction.value.dispatch.reload) {
        hideModal(document.getElementById(selectionData.value.modalId))
        emit('reload')
      }
    }).catch((error) => {
      Swal.fire({
              text: t('phrases.generalApiError'),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t('titles.Continue'),
              customClass: {
              confirmButton: "btn btn-primary",
              },
          });
          return false;
    })
  }

  const createDispatchData = (data) => {
    const template = {} as any
    const payloadLines = currentAction.value.dispatch.payloadConfig

    // Add data based on payloadConfig lines
    for (let i = 0; i < payloadLines.length; i++) {
      template[payloadLines[i].key] = []

      if (payloadLines[i].type == 'selection') {
        const key = payloadLines[i].key
        const selectedItems = selectionData.value.items

        for (let i = 0; i < selectedItems.length; i++) {
          template[key].push(selectedItems[i].id)
        }
      } else {
        template[payloadLines[i].key] = payloadLines[i].value
      }
    }

    // Add data based on transferData
    for (const [key, value] of Object.entries(data)) {
      template[key] = value
    }

    return template
  }

  return {
    selectionState,
    selectionSelected,
    selectionData,
    initToolbar,
    update,
    selectAll,
    multipleTableRef,
    deselectAll,
    setBackgroundTableRow,
    deleteSelected,
    loadAction,
    renderAmount,
    currentAction,
    selectionChange
  }
}