import { ref, computed, reactive } from "vue";
import useHelpers from "@/composables/v2/useHelpers";

export default function useTableRelationBadge() {
  const { userLanguage, isValidJsonString } = useHelpers();

  const badges = ref();

  const createRelationBadge = (data) => {
    if (!data) {
      return;
    }

    if (data.length === 0) {
      return;
    } else {

      const badgeItems = [] as any;

      for (const badge of data) {

        if (isValidJsonString(badge.name)) {
          const languageData = JSON.parse(badge.name);
          for (const [key, value] of Object.entries(languageData)) {
            if (key == userLanguage) {
              badgeItems.push(value)
            }
          }
        } else {
          badgeItems.push(badge.name)
        }
      }
      badges.value = badgeItems
    }
  }

  return {
    createRelationBadge,
    badges
  }
}