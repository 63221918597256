import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import useHelpers from "@/composables/v2/useHelpers";
import useLanguage from '@/composables/v2/useLanguage';
import useApp from '@/composables/v2/useApp';

export default function useDatav2() {
  const store = useStore();
  const { isValidJsonString } = useHelpers();
  const { convertToText } = useLanguage();
  const { getSettingOptions } = useApp();

  const createFilterString = (template, filterType?) => {
    let filterString = '?';
    for (let i = 0; i < template.length; i++) {
      const filter = template[i]
      const method = (filter.filterMethod == 'and') ? 'filter=' : 'or_filter='
 
      filterString += method + filter.filterKey + ':' + filter.filterOperator + ':' + filter.filterValue
    
      if (i !== template.length - 1) {
        filterString += '&'
      }
    }

    return (typeof filterString != 'undefined') ? filterString : ''
  }

  const getOptions = async (settings, filterTemplate?, componentData?, convertModus?, fieldName?) => {
    const { endpoint, getAll, selection, field } = settings
    let { link } = settings
    let options
    let propName: string;

    if (selection == 'route') {
      propName = field
      const filters = createFilterString(filterTemplate)

      // Get the most recent data
      options = await store.dispatch(Actions[endpoint], filters)
      .then(() => {
            return store.getters[getAll].items
      })

    } else if (selection == 'settings') {
      propName = 'label'
     
      // Addon for dynamic value in component builder
      if (link.match('@') ){
        const split = link.split(/\@\((.*?)\)/) //eslint-disable-line
        const search = componentData.find( field => field.name == split[1]).defaultValue
        link = split[2] + '.' + search;
        options = await getSettingOptions(fieldName, link);
      } else {
        options = await getSettingOptions(fieldName, settings.link);
      }
    }

    let newArray;
    if (typeof options !== 'undefined' && Object.keys(options).length !== 0) {
      newArray = options.map(item => ( { value: item.id, label: (isValidJsonString(item[propName]) == true) ? convertToText(item[propName], convertModus) : item[propName] } ));
    } else {
      newArray = []
    }
    return newArray
  }

  return {
    getOptions
  }
}