import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { hideModal, showModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import useDatav2 from "@/composables/v2/useData"
import useDateTime from "@/composables/v2/useDateTime"
import useReactive from "@/composables/v2/useReactive"
import useFile from "@/composables/v2/useFile"
import { Modal } from "bootstrap";
import useLanguage from '@/composables/v2/useLanguage';
import useComponentTree from "@/composables/v2/useComponentTree";
import useApp from '@/composables/v2/useApp';
import { usePOSStore } from "@/views/pos/stores/pos";
import { useI18n } from "vue-i18n";
import { fieldTemplate } from '@/composables/v2/types';
import { string } from "yup";

import useComponent from "@/composables/v3/components/useComponent";

const activeComponents = ref([]) as any
const renderId = ref(); // Global for filters component builder

export default function useComponentv2() {
  const posStore = usePOSStore();
  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const { convertToText } = useLanguage();
  const { createTree } = useComponentTree();
  const { getSetting } = useApp();
  const { t } = useI18n();
  const { processConditionals } = useComponent();

  const componentInit = ref() // Settings passed in a Vue component.
  const componentModule = ref() // Component data coming from the component builder.
  const componentData = ref({}) as any // Data needed to run the component within Vue (display).
  const componentStatus = ref(false);
  const componentRegisterId = ref();

  const renderAmount = ref(0);
  const reloadAmount = ref(0);

  const componentFilter = ref('')
  const componentParameters = ref('');
  const componentLoading = ref(true)
  const submitButtonComponent = ref(false)
  const submitLoadingComponent = ref(false)
  const transferData = ref({})
  const originalData = ref();

  const test = ref(false)
  const modalRef = ref<null | HTMLElement>(null);

  const { emit } = getCurrentInstance() as any;
  const { getOptions } = useDatav2();
  const { currentDateTime } = useDateTime();
  const { addReactive, reactiveElements } = useReactive();
  const { getFile, createFiles } = useFile();

  const allComponents = computed(() => {
    return store.getters.getComponents;
  });

  const getCurrentData = computed(() => {
    return store.getters[componentInit.value.currentData];
  });

  class Component {
    add = (name: string, type: string) => {
      const element = {
        name: name,
        type: type
      }
      activeComponents.value.push(element)
    }
  }

  const initComponent = async (settings, pageNumber?: number) => {
    const { componentType, dispatchActions } = settings

    // Set component settings
    componentInit.value = settings
    componentModule.value = await getComponent();

    if (componentModule.value) {
      componentRegisterId.value = componentModule.value.uid

      // Generate object for Vue component
      componentData.value.structure = componentModule.value

      if (componentType == "table") {
        componentData.value.data = await getData(componentType, null, pageNumber, dispatchActions?.init)
        componentData.value.actions = componentInit.value.componentTypeActions
        componentData.value.status = true
        componentData.value.toolbar = componentInit.value.componentToolbar
        componentData.value.expandable = componentInit.value.componentSettings?.expandable !== undefined ? componentInit.value.componentSettings.expandable : false
      } else if (componentType == "form") {
        componentData.value.data = await getData(componentType, componentModule.value.fields, pageNumber, dispatchActions?.init)
        componentData.value.status = true
        componentData.value.toolbar = componentInit.value.componentToolbar
        componentData.value.toggle = componentModule.value.toggle
      } else if (componentType == "upload") {
        componentData.value.data = await getData(componentType, componentModule.value.fields, pageNumber, dispatchActions?.init)
        componentData.value.status = true
        componentData.value.toolbar = componentInit.value.componentToolbar
      } else if (componentType == "tree") {
        componentData.value.data = await getData(componentType, componentModule.value.fields, pageNumber, dispatchActions?.init)
        componentData.value.status = true
        componentData.value.toolbar = componentInit.value.componentToolbar
      }

      originalData.value = componentData.value.data
      componentLoading.value = false
      componentStatus.value = true
    }
  }

  const getComponent = () => {
    const component = allComponents.value.find(e => e.name === componentInit.value.componentName)

    // setFilters({ method: 'api', settings: component.filters, status: ((component.filters == null) ? false : true)})
    setFilters(typeof component !== 'undefined' ? component.filters : null)
    if (componentInit.value.dispatchActions.params.init) {
      createParameters(componentInit.value.dispatchActions.params.lines)
    }

    return component
  }

  const createParameters = (lines) => {
    componentParameters.value = ''
    if (lines) {
      for (const line of lines) {
        const value = line.type == "stores" ? store.getters[line.source][line.value] : line.value
        componentParameters.value += `&${line.key}=${value}`
      }
    }
  }

  const reloadComponent = async (pageNumber = 1) => {
    componentLoading.value = true // Activate Loading

    if (componentInit.value.componentReload.state) {
      const reloadActions = componentInit.value.componentReload.actions;
      for (const action of reloadActions) {

        if (action.pathParam.state) {
          await store.dispatch(Actions[action.name], action.pathParam.value);
        }
      }
    }

    emit('reload') // Emit 'Reload' to all other components and ListenTo functionality to refresh components which are connected to each other
    await initComponent(componentInit.value, pageNumber) // Reload component itself
    reloadAmount.value += 1 // Rerender components if needed
  }

  const reloadValidation = () => {
    emit('reload')
  }

  const processRender = async (data, fieldsData) => {
    let options = [] as any;
    const currentFieldData = fieldsData.find(field => field.name === data.key);
    const currentFieldIndex = fieldsData.findIndex((field) => field.name === data.key);

    const { type, settings } = currentFieldData

    if (type === "selectSearch") {
      if (settings.selection == "settings") {
        // Based on v3 field
        const fieldToCheck = settings.fieldName;
        const configSource = settings.configSource
        const ftcData = fieldsData.find(field => field.name === fieldToCheck);

        if (ftcData) {
          const ftcDefaultValue = ftcData.defaultValue;
          options = await createSelectOptions({ dataMethod: settings.selection, configSource: configSource, ftcData: ftcData, ftcDefaultValue: ftcDefaultValue })
          componentData.value.data[currentFieldIndex].options = options
        }
      }
    }
  }

  const createSelectOptions = (data: any) => {
    const options = [] as any

    if (data.dataMethod == 'settings') {
      const path = data.configSource.split('.');
      const sourceOptions = path.length == 2 ? store.getters.appSettings[path[0]].find(item => item.id === data.ftcDefaultValue)[path[1]] : store.getters.appSettings[data.configSource].find(item => item.id === data.ftcDefaultValue);

      for (const option of sourceOptions) {
        const template = {
          value: option.id,
          label: option.label
        }

        options.push(template)
      }
    }

    return options;
  }

  const updateComponent = async (data) => {
    const { key, value, label, type, transferFill } = data.data
    const { method } = data

    // v3
    if (componentModule.value.conditionals !== null && componentModule.value.conditionals != '') {
      const conditionals = JSON.parse(componentModule.value.conditionals);
      processConditionals(key, value, conditionals, componentData.value.data);
    }

    processRender(data.data, componentData.value.data);

    // Test Table v0.1
    if (data.componentType == 'table') {
      const dispatchData = { routeId: data.data.id, params: { 'variation': value } };
      store.dispatch(Actions.PRODUCT_SPECIFICATIONS_RELATION_EDIT, dispatchData);
      return
    }

    // Retrieve orginal data to compare with current data
    const originalComponentData = originalData.value.find(e => e.name === data.data.key)

    if (data.method == 'selectSearch' || data.method == 'direct' && data.data.field == 'selectSearch' && data.data.value == '' || data.method == "selectMultiSearch") {
      // rrV3(key, value)
      reRenderSelect2(key, value, label, data.type, type)
      return;
    }

    if (data.method == 'uploadFiles') {
      componentLoading.value = true // Activate Loading
      await createFiles(value, componentInit.value)
      await reloadComponent()
      return;
    }

    if (type == 'configuration') {
      transferData.value[data.data.key] = (originalComponentData.validation == 'json') ? JSON.stringify(data.data.value) : data.data.value
    } else if (type == "textLanguageOptions") {
      transferData.value[data.data.key] = (originalComponentData.validation == 'json') ? JSON.stringify(data.data.value) : data.data.value
    } else if (type == "addressFields") {
      transferData.value[data.data.key] = (originalComponentData.validation == 'json') ? JSON.stringify(data.data.value) : data.data.value
    } else if (type == "selectMultiSearch") {
      if (value.length == 0) {
        transferData.value[key] = null
      } else {
        transferData.value[key] = (originalComponentData.validation == 'json') ? JSON.stringify(value) : value
      }
    } else if (type == "selectSearch") {
      if (key) {
        if (!value) {
          transferData.value[key] = null
        } else {
          transferData.value[key] = (originalComponentData.validation == 'json') ? JSON.stringify(value) : value
        }
      }
    } else {
      // Compare original and adjusted data
      // if (transferFill == false && originalComponentData.defaultValue == data.data.value && data.method != 'indirect' || originalComponentData.transferFill == false && originalComponentData.defaultValue == data.data.value  && data.method != 'indirect' || originalComponentData.defaultValue == null && data.data.value === '' || originalComponentData.defaultValue != null && data.data.value == null || originalComponentData.defaultValue == null && data.data.value.length == 0 ) {
      //   delete transferData.value[data.data.key]
      // } else if (transferFill === true && originalComponentData.defaultValue == data.data.value || originalComponentData.defaultValue != data.data.value) {
      transferData.value[data.data.key] = (originalComponentData.validation == 'json') ? JSON.stringify(value) : value
    }

    // Rerender other fields which are linked together.
    // if(method == "selectSearch" || method == "select2") {
    reRender(key, value, label, type)
    // }

    // Validate submit button
    Object.keys(transferData.value).length === 0 ? submitButtonComponent.value = false : submitButtonComponent.value = true

    // Translation, indirect
    if (data.method == 'indirect') {

      addReactive(key, value)

      for (const [key, value] of Object.entries(reactiveElements.value)) {

        const itemIndex = componentData.value.data.findIndex((item) => item.name === key)
        const item = componentData.value.data.find(({ name }) => name === key);

        // item.defaultValue = (originalData.value.validation == 'json') ? JSON.stringify(value) : value
        item.defaultValue = value
        componentData.value.data[itemIndex] = item
      }
    }

    if (data.data.save) {
      saveComponent();
    }
  }

  const saveComponent = () => {
    submitLoadingComponent.value = true
    componentLoading.value = true

    const { componentRedirect, componentReload, componentEmit } = componentInit.value

    if (componentInit.value.dispatchActions.params.init) {
      const lines = componentInit.value.dispatchActions.params.lines
      if (lines) {
        for (let i = 0; i < lines.length; i++) {
          transferData.value[lines[i].key] = lines[i].source ? store.getters[lines[i].source][lines[i].field] : lines[i].value
        }
      } else {
        transferData.value[componentInit.value.dispatchActions.params.key] = componentInit.value.dispatchActions.params.value
      }
    }

    // const dispatchData = (componentInit.value.displayMethod == 'existing') ? {id: componentInit.value.currentId, data: transferData.value} : transferData.value\
    const dispatchData = { routeId: componentInit.value.currentId, params: transferData.value };
    store.dispatch(Actions[componentInit.value.dispatchActions.save], dispatchData).then(async ({ data }) => {

      if (data) {
        const taskId = data.task_id;
        await posStore.checkTaskStatus(taskId);
      }

      // Clear transfer data
      transferData.value = {} /* Clear data */

      if (componentInit.value.displayMethod == 'existing') {
        // setTimeout( async () => {
        // await initComponent(componentInit.value) /* Reinitialisate component */
        // }, 500)
      } else if (componentInit.value.displayMethod == 'new') {
        hideModal(modalRef.value);
      }

      if (componentEmit) {
        const modal = document.getElementById(componentInit.value.modalId);
        const instance = Modal.getOrCreateInstance(modal)
        instance.toggle()

        emit('reload')
      }

      if (componentReload) {
        if (componentInit.value.modalId != undefined) {
          const modal = document.getElementById(componentInit.value.modalId)
          const instance = Modal.getOrCreateInstance(modal)
          instance.toggle()
          await initComponent(componentInit.value)
          setTimeout(async () => {
            emit('reload')
          }, 500)
        } else if (componentInit.value.componentType == 'upload') {
          await initComponent(componentInit.value)
          setTimeout(async () => {
            emit('reload')
          }, 500)
        }
      }

      if (componentRedirect.init) {
        setTimeout(() => {
          const route1 = {} as any;
          let dataPath;
          if (componentRedirect.path.includes('.')) {
            const test = componentRedirect.path.split('.')
            const array = componentRedirect.path.split(".").map((item, i) => route1[i] = item);
            dataPath = getCurrentData.value[array]
          } else {
            dataPath = getCurrentData.value[componentRedirect.path]
          }
          router.push({ path: componentModule.value.route + '/' + dataPath });
        }, 200);
      }

    }).catch((error) => {
      Swal.fire({
        text: t('phrases.generalApiError'),
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t('titles.Continue'),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
      return false;
    }).finally(() => {
      submitButtonComponent.value = false
      componentLoading.value = false;
      submitLoadingComponent.value = false;
    })
  }


  const setFilters = (data) => {
    componentFilter.value = '';

    if (data != null) {
      const settings = JSON.parse(data)

      for (let i = 0; i < settings.length; i++) {
        const { field, type, type_filterRoute, value, operator, method } = settings[i]
        let key;
        if (type == 'value') {
          key = value
        } else if (type == 'id') {
          key = componentInit.value.currentId
        } else if (type == "routeData") {
          key = store.getters[settings[i].value][settings[i].field]
        } else if (type == "parentRenderId") {
          key = renderId.value
        } else if (type == "storeData") {
          if (value.includes('.')) {
            const array = value.split('.')
            key = store.getters[array[0]][array[1]]
          }
        }

        if (method == "or") {
          componentFilter.value += '&or_filter=' + field + ':' + operator + ':' + key
        } else {
          componentFilter.value += '&filter=' + field + ':' + operator + ':' + key
        }
      }
    }


    if (componentInit.value.componentFilter.init) {
      componentFilter.value += createComponentFilterString(store.getters[componentInit.value.componentFilter.get].activated);
      // if (componentInit.value.componentName == 'SalesTable') {
      //   componentFilter.value += createComponentFilterString(store.getters[componentInit.value.componentFilter.get]);

      // } else {
      //   const string = store.getters[componentInit.value.componentFilter.get]['string']

      //   if (string !== undefined && string !== null) {
      //     componentFilter.value += store.getters[componentInit.value.componentFilter.get]['string']
      //   }
      // }
    }
  }

  const createComponentFilterString = (options) => {
    let filterString = ''
    if (options) {
      for (const option in options) {
        filterString += '&filter=' + option + ':=:' + options[option].join(',')
      }
    }
    return filterString;
  }

  const getOptionsFilter = (filters, fieldValue, filterType?) => {
    const template: Array<any> = []
    filters?.map((filter) => {

      let test

      if (filter.type_filterRoute == "currentForm" && filterType != 'currentForm') {
        const fieldData = transferData.value
        // const reduced = fieldData.filter(field => field.name == filter.field_filterRoute)

        test = fieldValue
        for (const [key, value] of Object.entries(fieldData)) {
          if (key == filter.field_filterRoute) {
            test = value;
          }
        }
      } else {
        test = (filter.type_filterRoute == 'currentForm' || filterType == 'currentForm') ? fieldValue : ''
      }

      const settingTemplate = {
        filterKey: filter.field_filterRoute,
        filterType: filter.type_filterRoute,
        filterOperator: filter.operator_filterRoute,
        filterMethod: filter.operator_filterMethod,
        filterValue: test
      }
      template.push(settingTemplate)
    })
    return (template.length > 0) ? template : null
  }


  const createFilterTemplate = (filters, fieldAction?, fieldValue?) => {
    let template: Array<any> = []
    let filterValue
    if (filters) {
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].filterType == 'currentForm') {
          if (Object.keys(transferData.value).length) {
            for (const [key, value] of Object.entries(transferData.value)) {
              if (key == filters[i].filterKey) {
                filterValue = value
              } else {
                filterValue = componentData.value.data.find(field => field.name == filters[i].filterKey).defaultValue
              }
            }
          } else {
            filterValue = componentData.value.data.find(field => field.name == filters[i].filterKey).defaultValue
          }
        } else if (filters[i].filterType == 'select2SearchInput') {
          filterValue = fieldValue
        } else if (filters[i].filterType == 'id') {
          filterValue = componentInit.value.routeId
        } else if (filters[i].filterType == "parentRenderId") {
          filterValue = renderId.value
        } else if (filters[i].filterType == "routeData") {
          filterValue = store.getters[filters[i].filterValue][filters[i].filterKey]
        } else if (filters[i].filterType == "customValue") {
          filterValue = filters[i].filterValue
        }

        const structure = {
          filterKey: filters[i].filterKey,
          filterType: filters[i].filterType,
          filterOperator: filters[i].filterOperator,
          filterMethod: filters[i].filterMethod,
          filterValue: filterValue
        }

        template.push(structure)
      }

      // Exclude select2SearchInput if action is 'getOptions'
      if (fieldAction == 'getOptions') {
        template = template.filter((filter) => filter.filterType !== 'select2SearchInput');
      }
    }
    return template
  }

  const reRenderSelect2 = async (fieldName, fieldValue, fieldLabel, fieldAction, fieldType) => {
    const originalComponentData = JSON.parse(componentModule.value.fields);
    const fieldsData = componentData.value.data
    const currentFieldData = fieldsData.filter(field => field.name == fieldName)
    const convertModus = fieldName == '' ? 'profile' : ''
    let options;
    let proceed1 = true;

    for (let i = 0; i < currentFieldData.length; i++) {
      const fieldFilters = currentFieldData[i].dynamics

      let filters = [] as any;
      if (currentFieldData[i].settings.selection === "route") {
        filters = createFilterTemplate(fieldFilters, fieldAction, fieldValue);
      }

      if (filters) {
        const active = filters.filter(filter => filter.filterType == 'currentForm')

        if (active.length) {
          for (let i = 0; i < active.length; i++) {
            if (active[i].filterValue == '') {
              proceed1 = false
            }
          }
        }
      }

      const fieldData = originalComponentData.find(({ field }) => field === currentFieldData[i].name);
      const fieldIndex = originalComponentData.findIndex((field) => field.field === currentFieldData[i].name)

      if (proceed1 == true) {
        options = await getOptions(fieldData.options, filters, fieldsData, convertModus, fieldData.field)

        // Check if the option is listed in the options list. If not, create it and place it at position 0.
        if (fieldAction == "getOptions" && fieldValue != '' && fieldValue != null) {

          // Exception for type: "select_multi"
          if (fieldType == "select_multi" || fieldType == "selectMultiSearch") {

            const selectedOptions = [] as any
            for (const key in fieldValue) {
              selectedOptions.push(fieldValue[key])
            }

            selectedOptions.forEach(function (item, index) {
              if (options.find(option => option.value === item)) {
                const elementIndexToReorder = options.findIndex(element => element.value === item)
                const elementToReinsert = options[elementIndexToReorder]
                options.splice(elementIndexToReorder, 1)
                options.splice(index, 0, elementToReinsert)
              } else {

                for (let i = 0; i < currentFieldData.length; i++) {
                  const activeValues = currentFieldData[i].helpers
                  const findItem = activeValues.find(element => element.id === item)
                  options.push({ value: item, label: findItem.name })
                }

                const elementIndexToReorder = options.findIndex(element => element.value === item)
                const elementToReinsert = options[elementIndexToReorder]
                options.splice(elementIndexToReorder, 1)
                options.splice(index, 0, elementToReinsert)
              }

            })
          } else {
            const search = options.find(option => option.value == fieldValue);
            const searchIndex = options.findIndex((option) => option.value === fieldValue)
            if (!search) {
              const currentObjectData = { value: fieldValue, label: fieldLabel }
              options.splice(0, 0, currentObjectData);
            } else {
              // Solution. If there is only 1 option that matches, there should be no duplicate with respect to that if the index numbering matches. 
              // Then don't run splice.
              if (options.indexOf(search) != 0) {
                // Delete item
                options.splice(searchIndex, 1);

                // Add item
                options.splice(0, 0, search);
              }
            }
          }
        }
      } else {
        options = []
      }

      // Update options and defaultValue of specific field in the global dataset.
      //componentData.value.data[fieldIndex].defaultValue = fieldValue
      componentData.value.data[fieldIndex].options = options
    }
  }

  class Conditionals {
    data: string;
    template;
    fieldName: string;
    fieldValue: string;
    conditionalStatus: boolean;

    constructor(data: string, fieldName: string, fieldValue: string, template) {
      this.data = data;
      this.template = template;
      this.fieldName = fieldName;
      this.fieldValue = fieldValue;
      this.conditionalStatus = false;
    }

    isFiltered() {
      let filtered;
      if (this.data.length > 0) {
        const conditionals = JSON.parse(this.data)
        filtered = conditionals.filter(conditional => conditional.watch_field == this.fieldName);
      } else {
        filtered = []
      }
      return filtered
    }

    isSelected(fieldName: string, watchField: string, value: string) {
      let selectedVisibility;
      for (let temp = 0; temp < this.template.length; temp++) {
        if (this.template[temp].name == watchField && this.template[temp].defaultValue == value) {
          selectedVisibility = true
        }
      }
      return selectedVisibility;
    }

    isActive() {
      if (this.data.length > 0) {
        const conditionals = JSON.parse(this.data)
        for (const conditional of conditionals) {
          if (conditional.target_field == this.fieldName && this.isSelected(this.fieldName, conditional.watch_field, conditional.value) != true) {
            this.conditionalStatus = true
          }
        }
      }
      return this.conditionalStatus
    }
  }

  const reRender = async (fieldName, fieldValue, fieldValueDisplay?, fieldType?) => {
    componentLoading.value = true
    const fieldData = componentData.value.data
    const currentFieldData = fieldData.filter(field => field.name == fieldName)

    // SalesLines
    if (fieldName == '') {
      const proceedData = store.getters.allProducts.items.filter(item => item.id === fieldValue)

      const prefillTemplate = [
        { originalField: 'product_id', externalField: 'id' },
        { originalField: 'name', externalField: 'title' },
        { originalField: 'price', externalField: 'price' }, // profile_prices.price
        { originalField: 'vat_percentage', externalField: 'vat_percentage' }, // profile_prices.vat_percentage
        { originalField: 'vat_included', externalField: 'vat_included' }, // 'profile_prices.vat_included'
      ]

      const getPrefillValue = (externalField) => {
        let search;
        let data;

        for (let i = 0; i < proceedData.length; i++) {

          if (externalField.includes('.')) {
            search = externalField.split('.')
            const profilePrices = proceedData[i][search[0]]
            const profileId = store.getters.currentSale.profile_id
            if (profilePrices.length > 0) {
              for (let i = 0; i < profilePrices.length; i++) {
                if (profilePrices[i].profile_id == profileId) {
                  data = proceedData[i][search[0]][0][search[1]]
                }
              }
            }
          } else {
            data = (externalField == 'title') ? convertToText(proceedData[i][externalField], 'profile') : proceedData[i][externalField]
          }
        }
        // const productData = store.getters.allProducts.items.filter(item => item.id === fieldValue).map((field => externalField.includes('.') ? field.search[0].search[1] : field[externalField]))
        return data;
      }

      // Loop for all prefillTemplate fields to manipulate
      for (let i = 0; i < prefillTemplate.length; i++) {
        const item = prefillTemplate[i]
        const newDefaultValue = getPrefillValue(item.externalField)
        const currentFieldIndex = fieldData.findIndex((field) => field.name === item.originalField)
        componentData.value.data[currentFieldIndex].defaultValue = newDefaultValue
      }
    }

    // Specific for country_code change
    if (fieldName.includes("country_code")) {
      let label;
      let column;
      let hidden;
      let resetDefaultValue;

      const addressFormats = store.getters.getSetting('address_formats');

      const addressFormat = addressFormats.find(format => format.id == fieldValue)
      const filteredAddressFormat = Object.keys(addressFormat)
        .filter((key) => key !== 'id')
        .reduce((obj, key) => {
          obj[key] = addressFormat[key];
          return obj;
        }, {})

      // Regenerate fields
      const addressFields = fieldData.filter(field => field.name.includes('address'));

      // Loop address fields
      for (let i = 0; i < addressFields.length; i++) {
        const field = addressFields[i]
        const name = addressFields[i].name

        for (const [key, value] of Object.entries(filteredAddressFormat)) {
          const string = name.replace('billing_', '');
          const string2 = string.replace('return_', '')

          if (key.includes(string2)) {
            label = value
          }
        }

        if (name.includes("address_1")) {
          column = 6
        } else {
          if (fieldValue == 'NL') {
            column = 3

            if (name.includes('address_4')) {
              hidden = true
              resetDefaultValue = true
            }
          } else if (fieldValue == 'BE') {
            column = 2
            hidden = false
          }
        }

        // Setup fields
        const currentFieldIndex = fieldData.findIndex((field) => field.name === name)
        componentData.value.data[currentFieldIndex].label = label
        componentData.value.data[currentFieldIndex].column = column
        componentData.value.data[currentFieldIndex].hidden = hidden

        if (resetDefaultValue == true) {
          componentData.value.data[currentFieldIndex].defaultValue = '' // Clear the def
          //transferData.value[name] = ''
        }
      }
    }


    // Start rerender fields based on currentForm
    const reduced = fieldData.filter(field => field.dynamics?.some(filter => filter.filterKey === fieldName && filter.filterType == 'currentForm'))
    const currentFieldIndex = fieldData.findIndex((field) => field.name === fieldName)

    componentData.value.data[currentFieldIndex].defaultValue = fieldValue
    componentData.value.data[currentFieldIndex].defaultValueDisplay = fieldValueDisplay

    // All fields that have fieldName within the field filters.
    for (let i = 0; i < reduced.length; i++) {
      const fields = JSON.parse(componentModule.value.fields);
      const parentData = fields.find(({ field }) => field === reduced[i].name);
      const parentIndex = fields.findIndex((field) => field.field === reduced[i].name)

      // Generate new options
      const filters = getOptionsFilter(parentData.options.route_filter, fieldValue, 'currentForm')

      // Remove from TransferData
      for (const [key, value] of Object.entries(transferData.value)) {
        if (key == reduced[i].name) {
          delete transferData.value[key]
        }
      }

      // Update field data
      componentData.value.data[parentIndex].defaultValueDisplay = null
      componentData.value.data[parentIndex].defaultValue = null
      componentData.value.data[parentIndex].options = []
    }

    // Start rerender based on conditionals
    // const length = currentFieldData.length;
    // for (let i = 0; i < length; i++) {
    //   const { conditionals } = currentFieldData[i];
    //   if (conditionals.length > 0) {
    //     const filteredArray = conditionals.filter(obj => obj.watch_field === fieldName);
    //     const targetFieldsStatus = [] as any;
    //     for (const conditional of filteredArray) {
    //       const targetField = conditional.target_field;
    //       const conditionalIndex = fieldData.findIndex(field => field.name === targetField);
    //       const targetIndex = fieldData.findIndex(field => field.name === targetField);
    //       if (!targetFieldsStatus.some(obj => obj.targetField === targetField)) {
    //         if (conditional.value === fieldValue) {
    //           targetFieldsStatus.push({ targetField, result: conditional.result, targetIndex });
    //         } else {
    //           componentData.value.data[conditionalIndex].hidden = true;
    //         }
    //       }
    //     }
    //     for (const { targetIndex, result } of targetFieldsStatus) {
    //       if (result === 'show') {
    //         componentData.value.data[targetIndex].hidden = false;
    //       }
    //     }
    //   }
    // }

    // Disable loading
    componentLoading.value = false
  }

  const setDynamicSetting = (setting) => {
    if (setting) {
      const template: Array<any> = []

      for (let i = 0, len = setting.length; i < len; i++) {
        const settingTemplate = {
          filterKey: setting[i].field_filterRoute,
          filterOperator: setting[i].operator_filterRoute,
          filterType: setting[i].type_filterRoute,
          filterMethod: setting[i].operator_filterMethod,
          filterValue: setting[i].value
        }
        template.push(settingTemplate)
      }
      return template
    } else {
      return null
    }
  }

  const createParamList = (data) => {
    const list = {};
    const lines = data.lines;

    if (data.init) {
      for (let i = 0; i < lines.length; i++) {
        list[lines[i].key] = lines[i].value
      }
    }
    return list
  }

  const createParamString = (data) => {
    const list = {};
    const lines = data.lines;

    if (data.init) {
      for (let i = 0; i < lines.length; i++) {
        list[lines[i].key] = lines[i].value
      }
    }

    return list
  }

  const getData = async (method, componentFields, pageNumber = 1, componentDispatch?) => {
    let data;
    if (method === "table") {
      if (componentDispatch == 'PRODUCT_SPECIFICATIONS_PRODUCT' || componentDispatch == 'PRODUCT_SUPPLIERS_PRODUCT') {
        const paramsList = await createParamList(componentInit.value.dispatchActions.params)

        data = store.dispatch(Actions[componentDispatch], paramsList).then(() => {
          return store.getters[componentInit.value.currentData]
        })
      } else if (componentDispatch === 'PRODUCT_SPECIFICATION_OPTIONS_PRODUCT') {
        data = store.dispatch(Actions[componentDispatch], '?product_id=' + componentInit.value.routeId + componentFilter.value).then(() => {
          return store.getters[componentInit.value.currentData]
        })
      } else {
        data = store.dispatch(Actions[componentDispatch], '?page=' + pageNumber + componentFilter.value + componentParameters.value).then(() => {
          return store.getters[componentInit.value.currentData]
        })
      }
    } else if (method === "tree") {
      const externalData = await store.dispatch(Actions[componentDispatch], '?page=' + pageNumber + componentFilter.value).then(() => {
        return store.getters[componentInit.value.currentData]
      })

      data = await createTree(externalData)

    } else if (method === "form" || method === "upload") {
      const template = [] as any;
      const displayMethod = componentInit.value.displayMethod

      if (displayMethod == 'existing') { // Retrieve data only when existing data is to be used.
        await store.dispatch(Actions[componentDispatch], componentInit.value.currentId)

      }
      // await store.dispatch(Actions[componentDispatch], componentInit.value.currentId).then(async () => { 
      const fields = JSON.parse(componentFields);
      // Create all fields
      await fields.map((field) => {
        let defaultValue;
        let defaultValueDisplay;
        let transferFill;

        if (displayMethod == 'existing') {
          if (field.type == "sliderNumber") {
            defaultValue = Number(getCurrentData.value[field.field]);
          } else if (field.type == "selectMultiSearch") {
            defaultValue = getCurrentData.value[field.field] == null ? [] : (field.validation == 'json') ? JSON.parse(getCurrentData.value[field.field]) : getCurrentData.value[field.field];
          } else if (field.type === 'configuration') {
            const connection = store.getters.currentConnection
            const settingConnections = store.getters.getSetting('connections');
            const template = [] as any
            for (const [key, value] of Object.entries(settingConnections)) {

              if (key == connection.connection_type) {
                for (const test of Object.values(value as any)) {
                  const fieldData = JSON.parse(getCurrentData.value[field.field]);
                  const internalTemplate = test as any;

                  if (fieldData) {
                    for (const line of fieldData) {
                      if (internalTemplate.key == line.key) {
                        internalTemplate.value = line.value
                      }
                    }
                  }
                  template.push(internalTemplate)
                }
              }
            }

            defaultValue = template

          } else {
            defaultValue = (field.validation == 'json') ? JSON.parse(getCurrentData.value[field.field]) : getCurrentData.value[field.field];
          }

          // The defaultValue to be used should not be sent automatically as an update. So prevent the update from being included within transferData
          transferFill = false

        } else if (displayMethod == 'new') {
          if (field.type === 'dateTime') {
            defaultValue = currentDateTime()
          } else if (field.type === 'uploadImageSingle') {
            defaultValue = []
          } else if (field.type === 'number') {
            defaultValue = 0
          } else {
            defaultValue = null
          }

          // The defaultValue to be used should be sent automatically as an update. The update is therefore included within transferData.
          transferFill = (defaultValue === '' || defaultValue === null) ? false : true
        }

        // Create new entry
        const newElement: fieldTemplate = {
          name: field.field,
          label: field.label,
          type: field.type,
          defaultValue: defaultValue,
          defaultValueDisplay: null,
          displayMethod: displayMethod,
          options: null,
          validation: field.validation,
          disabled: field.disabled,
          hidden: (typeof field.hidden != 'undefined' ? field.hidden : false),
          transferFill: transferFill,
          conditionals: null,
          dynamics: setDynamicSetting((field.type == 'select2' && field.options.route_filter != 'undefined') || (field.type == 'selectSearch' && field.options.route_filter != 'undefined') || (field.type == 'select_multi' && field.options.route_filter != 'undefined') || (field.type == 'selectMultiSearch' && field.options.route_filter != 'undefined') ? field.options.route_filter : null),
          position: fields.findIndex(object => { return object.field === field.field; }),
          column: (typeof field.cols != 'undefined') ? field.cols : 12,
          settings: field.options || null,
          helpers: null,
          currentData: componentInit.value.currentData
        }


        // Create data set for fields
        template.push(newElement);
      })

      // Create defaultValue and options
      const promises = Promise.all(
        template.map(async (field) => {
          const filterTemplate: Array<any> = []

          // Fix for address fields dynamic
          if (field.type == 'addressFields') {
            const countryCode = template.find(field => field.name.includes('country_code')).defaultValue

            if (countryCode) {

              const addressFormats = store.getters.getSetting('address_formats');

              const addressFormat = addressFormats.find(format => format.id == countryCode)

              const filteredAddressFormat = Object.keys(addressFormat)
                .filter((key) => key !== 'id')
                .reduce((obj, key) => {
                  obj[key] = addressFormat[key];
                  return obj;
                }, {})



              for (const [key, value] of Object.entries(filteredAddressFormat)) {
                const string = field.name.replace('billing_', '');
                const string2 = string.replace('return_', '')

                if (key.includes(string2)) {
                  field.label = value
                }
              }


              if (field.name.includes('address_1')) {
                field.column = 6
              } else {
                if (countryCode == 'NL') {
                  field.column = 3

                  if (field.name.includes('address_4')) {
                    field.hidden = true
                  }
                } else if (countryCode == 'BE') {
                  field.column = 2
                }
              }
            } else {
              field.hidden = true
            }
          }

          if (field.type == "select2" || field.type == "select_multi" || field.type == "selectSearch" || field.type == "selectMultiSearch") {
            // Rules
            // 1. Options must always contain an empty Array.
            // 2. If the defaultValue of a specific is 'null', you do not need to retrieve it.

            // Default options is empty
            field.options = []

            // if (field.defaultValue != null) {
            //REMOVED

            // Exclude 'select2SearchInput' filters, as these are only used when rendering.
            const fieldFilters = (typeof field.settings.route_filter != 'undefined') ? field.settings.route_filter.filter(filter => filter.type_filterRoute != "select2SearchInput") : null

            // Check if specific field has filter options
            // If there is a filter with 'currentForm', see if its parent has a defaultValue.
            if (fieldFilters != null) {
              let proceed = true;
              field.options = [] // Default empty optionlist

              await fieldFilters.map(async (filter) => {

                // By default it will not retrieve options by API.
                let filterValue;

                if (filter.type_filterRoute == 'currentForm') {
                  const parentField = template.find(field => field.name == filter.field_filterRoute).defaultValue

                  if (parentField != '') {
                    proceed = true

                    // Setup filtersettings
                    const settingTemplate = {
                      filterKey: filter.field_filterRoute,
                      filterType: filter.type_filterRoute,
                      filterOperator: filter.operator_filterRoute,
                      filterMethod: filter.operator_filterMethod,
                      filterValue: parentField
                    }

                    filterTemplate.push(settingTemplate)
                  } else {
                    proceed = false
                  }
                } else {
                  proceed = true
                }
              })

              // If proceed is true, get the options through the API.
              if (proceed == true) {
                if (field.displayMethod == 'existing') {
                  if (field.defaultValue != null) {
                    const settingTemplate = {
                      filterKey: "id",
                      filterType: "id",
                      filterOperator: "=",
                      filterMethod: "and",
                      filterValue: field.defaultValue
                    }

                    filterTemplate.push(settingTemplate)

                    const options = await getOptions(field.settings, filterTemplate);

                    if (field.type == "selectMultiSearch") {
                      field.defaultValueDisplay = options.map(x => x.value)
                      field.options = options.map(x => ({ value: x.value, label: x.label }))

                      // Ensuring that options that are not provided on page 1 from the API are readable in their entirety (such as label, among others)
                      // for the settlement of the options list that is created through ReRender.
                      field.helpers = store.getters[field.settings.getAll].items
                    } else {
                      if (options.length) {
                        field.defaultValueDisplay = options[0].label
                      }
                    }
                  }
                }

                // Change options data set
                if (field.type != "selectMultiSearch") {
                  field.options = []
                }
              }
            } else {
              field.options = await getOptions(field.settings, filterTemplate, template)
              field.defaultValueDisplay = field.defaultValue
            }
            // } 
          } else if (field.type == "uploadImageSingle") {
            field.options = await getFile(field.defaultValue)
          } else if (field.type == "number") {
            field.settings = createSettings(field, template)
          }

          // Conditionals
          const conditional = new Conditionals(componentModule.value.conditionals, field.name, field.defaultValue, template)
          if (conditional.isActive() == true) {
            field.hidden = true
          }
          field.conditionals = conditional.isFiltered();
        })
      )
      await promises

      // Sort all fields by the set positions.
      data = template.sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
    }
    return data;
  }

  const createSettings = (fieldTemplate, dataTemplate) => {
    const template = {}

    interface Number {
      minimumQuantity: number | string
      maximumQuantity: number | string
    }

    // if (fieldTemplate.displayMethod == 'existing') {
    if (fieldTemplate.type == 'number') {
      for (const [key, value] of Object.entries(fieldTemplate.settings)) {
        const settingValue = value as any
        if (/^\d+$/.test(settingValue)) {
          template[key] = Number(value)
        } else {
          template[key] = getCurrentData.value[settingValue]
        }
      }
    }
    // }

    return template;
  }

  const renderModal = (id): void => {
    renderAmount.value += 1
    renderId.value = id

    // Hide previous modal
    if (componentInit.value.modalId != null) {
      const modal = document.getElementById(componentInit.value.modalId)
      const instance = Modal.getOrCreateInstance(modal)
      instance.hide()
    }
  }

  return {
    initComponent,
    reloadComponent,
    updateComponent,
    saveComponent,
    componentData,
    componentRegisterId,
    componentLoading,
    submitButtonComponent,
    submitLoadingComponent,
    componentStatus,
    modalRef,
    test,
    componentFilter,
    renderAmount,
    renderId,
    renderModal,
    reloadAmount,
    reloadValidation,
    Component,
    transferData
  }
}