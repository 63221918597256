import { ref, computed } from "vue";
import { useStore } from "vuex";

export default function useHelpers() {
  const store = useStore();

  const userLanguage = store.getters.currentUser.language;

  const tooltipStatus = ref(false);
  const tooltipContent = ref();

  const getShortString = (string, maxLength, scope) => {
    if (string == null) {
      return;
    }    
    
    const shrink = string.length > maxLength ? true : false
    let shortedString = string

    if (shrink == true) {
      shortedString = string.substring(0, maxLength).replace(/^\s+|\s+$/gm,'').concat('...')
      tooltipContent.value = string
      tooltipStatus.value = false
    } else {
      tooltipStatus.value = true
    }

    return shortedString;
  }

  const isValidJsonString = (data) => {
    if (data == null || data == "" || data.toString().match(/^[0-9]+$/) != null) {
      return false;
    }
    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }
    return true;
  }

  return {
    isValidJsonString,
    getShortString,
    tooltipStatus,
    tooltipContent,
    userLanguage
  }
}