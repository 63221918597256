
import { defineComponent, computed, ref, onMounted, nextTick, watch } from "vue";
import useComponentv2 from '@/composables/v2/useComponent';
import FormTypes from '@/components/forms/v2/Types.vue';
import useLanguage from '@/composables/v2/useLanguage';
import { Modal } from "bootstrap";

export default defineComponent({
  name: "",
  components: {
      FormTypes,
  },
  props: {
      modalSettings: Object,
      modalId: String,
  },
  setup(props, { emit }) {
    const { initComponent, componentData, componentLoading, updateComponent, submitButtonComponent, submitLoadingComponent, saveComponent, componentStatus, modalRef, reloadComponent, transferData } = useComponentv2();

    initComponent({
      componentName: props.modalSettings?.dispatch.modal.componentName, 
      displayMethod: props.modalSettings?.dispatch.modal.displayMethod, /* new or existing */
      componentType: props.modalSettings?.dispatch.modal.componentType, /* form or table */
      componentFilter: { init: false },
      modalId: props.modalId,
    });

    onMounted(() => {
        const modal = document.getElementById(props.modalId!)
        const element = Modal.getOrCreateInstance(modal)
        element.show()
    });

    return {
      componentData,
      componentLoading,
      componentStatus,
      updateComponent,
      submitButtonComponent,
      submitLoadingComponent,
      saveComponent,
      modalRef,
      reloadComponent,
      transferData
    };
  }
});
