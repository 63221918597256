export default function useTableCompare() {
  
  const maximumCompare = (data, rowData, itemName: string, itemOptions) => {
    if (data == undefined) {
      return;
    }

    // Test Object
    // const template = [ { fieldName: 'billed_quantity', compareTo: 'quantity', settings: { matchColorClass: 'success', unMatchColorClass: 'danger' } },
    //                    { fieldName: 'picked_quantity', compareTo: 'quantity', settings: { matchColorClass: 'success', unMatchColorClass: 'danger' } },
    //                    { fieldName: 'prepared_quantity', compareTo: 'quantity', settings: { matchColorClass: 'success', unMatchColorClass: 'danger' } },
    //                    { fieldName: 'returned_quantity', compareTo: 'quantity', settings: { matchColorClass: 'success', unMatchColorClass: 'danger' } } 
    //                    ] as any

    // const fieldData = template.find(item => item.fieldName === itemName)

    const matchColorClass = 'success'
    const misMatchColorClass = 'danger'
    const compareTo = parseInt(rowData[itemOptions.fieldName])
    const orginalField = parseInt(rowData[itemName])

    const html: string = '<span class="badge mb-1 badge-light-' + ((orginalField < compareTo) ? misMatchColorClass : matchColorClass) + '">' + data + '</span>'
    return html
  }

  return {
    maximumCompare
  }
}