export default function useComponent() {

  const processConditionals = (fieldName, fieldValue, cConditionals, fieldsData) => {
    const filteredConditionals = cConditionals.filter(c => c.watch_field === fieldName);

    if (filteredConditionals.length) {
      const targetFieldsStatus = [] as any;
      for (const conditional of filteredConditionals) {
        const targetField = conditional.target_field;
        const conditionalIndex = fieldsData.findIndex(field => field.name === targetField);
        const targetIndex = fieldsData.findIndex(field => field.name === targetField);
        if (!targetFieldsStatus.some(obj => obj.targetField === targetField)) {
          if (conditional.value === fieldValue) {
            targetFieldsStatus.push({ targetField, result: conditional.result, targetIndex });
          } else {
            fieldsData[conditionalIndex].hidden = true;
            fieldsData[conditionalIndex].defaultValue = null;
          }
        }
      }
      for (const { targetIndex, result } of targetFieldsStatus) {
        if (result === 'show') {
          fieldsData[targetIndex].hidden = false;
        }
      }
    }
  }

  return {
    processConditionals
  }
}