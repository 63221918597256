
import { defineComponent, ref, onMounted, watch, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n"
import useComponentv2 from '@/composables/v2/useComponent';
import useLanguage from '@/composables/v2/useLanguage';
import useFile from '@/composables/v2/useFile';
import Swal from "sweetalert2/dist/sweetalert2.js";
import Icon from '@/components/icons/Icon.vue';
import Editor from "@tinymce/tinymce-vue";
import { genFileId } from 'element-plus'
import type { UploadInstance, UploadProps, UploadRawFile } from 'element-plus'
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";

export default defineComponent({
	name: '',
	props: {
		fieldType: String,
		fieldLabel: String,
		fieldOptions: Object,
		fieldValue: [String, Object, Number],
		fieldValueDisplay: [String, Array],
		fieldName: String,
		fieldValidation: String,
		fieldDisabled: Boolean,
		fieldHidden: Boolean,
		fieldAddon: Object,
		fieldSettings: Object,
		fieldTransferFill: Boolean,
    currentData: String,
	},
	emits: ["gateway", "modal", "rebuild", "languageModal", "reload"],
	components: {
    	Icon,
			Editor,
      CodeHighlighter,
  	},
	setup(props, { emit }) {

		const store = useStore();
    const { t } = useI18n();
		const { convertToText } = useLanguage();
		const { componentLoading } = useComponentv2()
    const { createList, uploadFilesRef } = useFile();

		const defaultValue = ref(props.fieldValue)

    const value1 = ref([])
		const countryCode = ref()

		const loading = ref(false)
		const options = ref([])

		const upload = ref<UploadInstance>()

		// Generate Address fields
		const getAddressFormats = () => {
			if (countryCode.value) {
        const addressFormats = store.getters.action('address_formats');
				const addressFormat = addressFormats.find(format => format.id == countryCode.value)
															
				const filteredAddressFormat = Object.keys(addressFormat)
    																	.filter((key) => key !== 'id')
		    															.reduce((obj, key) => {
        																obj[key] = addressFormat[key];
        																return obj;
  																		}, {})

				const addressFields = [] as any
				let count = 0;

				const cols = (count) => {
						let col;
						if (count == 1) {
							col = 6
						} else { 
							if (countryCode.value == 'NL') {
								col = 3
							} else if (countryCode.value == 'BE') {
								col = 2
							}
						}
						return col
					}

					for (const [key, value] of Object.entries(filteredAddressFormat)) {
						count++
						addressFields.push({ name: key, defaultValue: store.getters.currentCAddress[key], label: value, cols: cols(count)});
					}
			
		  	return addressFields
			}
		}

		const handleExceed: UploadProps['onExceed'] = (files) => {
			upload.value!.clearFiles()
			const file = files[0] as UploadRawFile
			file.uid = genFileId()
			upload.value!.handleStart(file)
		}

		const getDefaultOptions = (key, value, label?, type?) => {
			loading.value = true
			emit("gateway", {method: 'selectSearch', type: 'getOptions', data: {key: key, value: value, label: label ? label.label : props.fieldValueDisplay , type: type}});
		}

		const remoteMethod = (query: string) => {
			loading.value = true
			emit("gateway", {method: 'selectSearch', type: 'searchOptions', data: {key: props.fieldName, value: query }});
			loading.value = false
		}

		// Multi upload
		const uploadFile = (file) => {
			const fileMb: number = parseInt((file.size / ( 1024 * 1024 )).toFixed(2));

			if (fileMb <= 2) {
				const imageList = new FormData()
				imageList.append('file[]', file.raw)

				const config = {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}

				store.dispatch(Actions.FILE_CREATE, {imageList: imageList, config: config}).then(() => {
					updateField(props.fieldName, store.getters.currentFile.id, null, '', props.fieldType, '', true)
				})
				.catch((error) => {
					Swal.fire({
						text: t('phrases.generalApiError'),
						icon: "error",
						buttonsStyling: false,
						confirmButtonText: t('titles.Continue'),
						customClass: {
							confirmButton: "btn btn-primary",
						},
					});
				})
			} else {
				Swal.fire({
					text: "Media size exceeds 2MB. Upload a file smaller than 2MB.",
					icon: "error",
					buttonsStyling: false,
					confirmButtonText: t('titles.Continue'),
					customClass: {
						confirmButton: "btn btn-primary",
					},
				});
			}
		}

		const updateField = (key, value, label, validation, type, disabled, save?) => {
			componentLoading.value = true
			if (key == 'country_code') {
				countryCode.value = value;
			}
			emit("gateway", {method: 'update', data: {key: key, value: value, label: label ? label.label : '', validation: validation, transferFill: props.fieldTransferFill, disabled: disabled, type: props.fieldType, save: save}});
			emit("rebuild", {key: key, value: value})
		}

		const loadLanguageModal = (fieldName, fieldValue, fieldType, currentData) => {
			emit("languageModal", { fieldName: fieldName, fieldValue: fieldValue, fieldType: fieldType, currentData: currentData })
		}

		const init = () => {
			if (props.fieldTransferFill) {
				emit("gateway", {method: 'direct', data: {key: props.fieldName, value: props.fieldValue, validation: props.fieldValidation, transferFill: props.fieldTransferFill, disabled: props.fieldDisabled, type: props.fieldType}});
			}
		}

		// Using is for reRender
		watch(() => props.fieldOptions, () => { 
			loading.value = false
			defaultValue.value = props.fieldValue
		})

		// Specific watch for translation modal + update sale line select
		// watch(() => props.fieldValue, () => { 
    //   defaultValue.value = props.fieldValue
    //   //emit("gateway", {method: 'direct', data: {key: props.fieldName, value: props.fieldValue, validation: props.fieldValidation, disabled: props.fieldDisabled, type: props.fieldType}});
		// 	// if (props.fieldType == "textLanguageOptions" || props.fieldType == "HTMLeditorLanguages") {
		// 	// 	defaultValue.value = props.fieldValue
		// 	// }

		// 	if (props.fieldType?.includes('address')) {
		// 		emit("gateway", {method: 'direct', data: {key: props.fieldName, value: props.fieldValue, validation: props.fieldValidation, disabled: props.fieldDisabled, type: props.fieldType}});
		// 	} else {
    //     if (props.fieldName)
    //     emit("gateway", {method: 'update', data: {key: props.fieldName, value: props.fieldValue }});
    //   }
		// })

    const createShortcodes = async (editor) => {
      const shortcodes = store.getters.appSettings.shortcodes.email_templates; 
      const specific = shortcodes.SaleInvoice

    	const data = [
        { id: "[customer_firstname]", label: "[customer_firstname]" },
        { id: "[customer_lastname]", label: "[customer_lastname]" },
      ];

      const items = [] as any

        for (let i = 0; i < specific.length; i++) {
          const shortcode = specific[i];

          let item = {
            type: 'menuitem',
            text: shortcode.label,
            onAction: () => editor.insertContent(shortcode.id)
          }
          items.push(item)
        }
      return items
    }

		onMounted(() => {
			init()
			if (props.fieldName == 'country_code') {
				countryCode.value = props.fieldValue
			}

			if (props.fieldType == 'selectSearch') {
				defaultValue.value = props.fieldValueDisplay
			}
		})

		return {
			defaultValue,
			countryCode,
			updateField,
			loadLanguageModal,
			uploadFile,
			//reRender
			componentLoading,
			convertToText,
			options,
			remoteMethod,
			loading,
			handleExceed,
			getDefaultOptions,
			getAddressFormats,
      uploadFilesRef,
      upload,
      createList,
      value1,
      store,
      createShortcodes,
      t
		};
	}
});
